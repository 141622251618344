import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'

import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";

export default class ErpDashboardList extends DefaultController {
    tableElem: any;
    datatable: any;

    async init() {
        this.entity = "erp/dashboard"
        await super.init();
        await this.loadAll();
    }

    async loadAll() {
        const r = await Utils.entity.getAll(this.entity);
        if (r.status === 200) {
            const entities = r.data;
            const entity = entities[0];
            const form = document.querySelector("#erpDashboard") as HTMLFormElement;
            await Utils.updateElements(entity, '', form)
        }
    }

    getEntityData(elem: any) {
        return {}
    }

    bindListeners() {
    }
}